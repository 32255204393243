import React from 'react'
import Helmet from 'react-helmet'
import { Link } from "gatsby"

import Layout from '../components/layout'
 
import pic11 from '../assets/images/p11.jpg'

const SaludMental = ({props, data}) => (
    <Layout>
        <Helmet>
            <title>Salud Mental y Creatividad - RevelARTE</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="bgtitle" style={{backgroundImage: `url(${pic11})`}}>
                        <h1>Salud mental y Creatividad</h1>
                    </header>
                    <h3>¿Por qué generar espacios cuidados donde podamos explorar, conocernos y crear?

</h3>
                    
                    <div class="grid-wrapper">
                        
                        <div class="col-6">
                        
                        
                        <p>Porque sabemos también que el cambio y lo nuevo, puede ocasionar estados de alerta y
en palabras de Pichón Riviere activar los miedos o ansiedades; en palabras de Rolnik
generan sensación de “extrañamiento. Sin embargo sabemos que poder transitar estas
sensaciones dentro de un dispositivo que permita la utilización de recursos y un
encuadre que tenga presente la empatía y el cuidado, permite transformar los miedos y
las sensaciones de extrañamientos en propuestas, en aprendizajes y búsquedas, llenas
de vitalidad, de transformación del afuera y de uno mismo.</p>

Para Pichón estamos hablando Salud mental o adaptación activa de la realidad, generar
los cambios, adaptarnos, pero también generar aprendizaje y modificaciones en nuestra
realidad, a través del enfrentamiento, experimentación e integración de los conflictos.Somos sujetos de contradicciones. Para la Psicología Social, sobre la cual establecemos nuestro encuadre, los pares contradictorios que se presentan en los procesos singulares y grupales, son motor para la promoción de transformaciones creativas de lo existente, transformación constructiva del medio, y de uno mismo.
<br/><br/>
</div>
<div class="col-6"><p>

<blockquote>
Tomamos el concepto de “Estado Creativo: Concepto netamente dinámico fruto de acciones, actos, ejercicios que
desbloquean todos los condicionamientos de los necesarios códigos que requieren el
acceso a la cultura; volvemos al punto de partida una sensibilidad abierta a las
sensaciones que nos conectan con lo inmediato de adentro de nosotros mismos.” (F.Moccio)</blockquote>
<br/> 
Es por esto que este proyecto busca proponer un espacio para que que otrxs puedan
desarrollar procesos creativos y por supuesto también nosotras, en este largo camino
de la vida que es conocernos y transformarnos.
</p>

<ul className="actions">
                                <li><Link to="/creatividad" className="button special big">Creatividad</Link></li>
                                <li><Link to="/elarte" className="button special big">Arte</Link></li>
 
                            </ul>
</div>
                     

 

                    </div>
                  
                    </div>





            </section>
        </div>

    </Layout>
)




export default SaludMental